import React from 'react';
import axios from 'axios';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import './css/main.css';

class App extends React.Component{

    constructor() {
        super();
        this.state = {
            currentRoom: "",
            username: this.getCookie("username"),
            rooms: [],
            tempRooms: [],
            permRooms: [],
            userLevel: 0
        };
        this.JitsiAPI = null;
        this.password = null;
        this.jwt = null;
        this.getRoomList();
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleRoomClick = this.handleRoomClick.bind(this);
        library.add(fab, far, fas);
    }

    handleUsernameChange(event) {
        this.setState({username: event.target.value});
        this.setCookie("username", event.target.value);
        this.getJWT();
    }

    handleRoomClick(event) {
        this.enterRoom(event.target.value)
    }

    getCookie(name) {
        if (document.cookie.includes(name)) {
            return document.cookie.replace("/(?:(?:^|.*;\s*)" + name + "\s*\=\s*([^;]*).*$)|^.*$/", "$1").split('=')[1];
        } else return null;
    }

    setCookie(key, value) {
        let domain = window.location.href.split('/')[2].split(':')[0];
        document.cookie = key + "=" + value + "; max-age=31536000; path=/; domain=" + domain;
    }

    httpRequest(method, endpoint, params) {
        switch (method) {
            case "get":
                return axios.get(window.API_URL + endpoint)
                    .then(function (response) {
                        return response.data;
                    });
            case "post":
                return axios.post(window.API_URL + endpoint, params)
                    .then(function (response) {
                        return response.data;
                    });
            default:
                break;
        }
    }

    getJWT() {
        let params = new URLSearchParams();
        params.append('pass', this.password);
        params.append('user', this.getCookie("username"));
        params.append('room', "");

        this.httpRequest("post", "/getJWT.php", params)
            .then(data =>{
                this.jwt = data;
            });
    }

    getRoomList() {
        setInterval(() => {
            this.httpRequest("get", "/getRooms.php", null)
                .then(data =>{

                    let roomLength = data.length;
                    let tempRooms = [];
                    let permRooms = [];

                    for (let i = 0; i < roomLength; i++) {
                        if (data[i].temp === "1") {
                            tempRooms.push(data[i])
                        } else {
                            permRooms.push(data[i])
                        }
                    }

                    this.setState({
                        rooms: data,
                        tempRooms: tempRooms,
                        permRooms: permRooms
                    })
                })
        }, 5000);
    }

    renderRooms() {

        return(
            <div className="rooms">
                {this.state.rooms.length === 0 ?
                    <FontAwesomeIcon className="spinner" icon={['fas', 'sync-alt']} /> : ""}
                <div className="rooms-perm">
                    {
                        this.state.permRooms.map((room, i)=>(
                            <input type="button" className={this.state.currentRoom === room.name ? "room room-active" : "room"} value={room.name} onClick={this.handleRoomClick} />
                        ))
                    }
                </div>
                <div className="rooms-temp">
                    {
                        this.state.tempRooms.map((room, i)=>(
                            <input type="button" className={this.state.currentRoom === room.name ? "room room-active" : "room"} value={room.name} onClick={this.handleRoomClick} />
                        ))
                    }
                </div>
            </div>
        );
    }

    enterRoom(name) {
        this.setState({
            currentRoom: name,
        });
        document.getElementById('main').innerHTML = "";
        let options = {
            roomName: name,
            width: document.getElementById('main').clientWidth,
            height: document.getElementById('main').clientHeight,
            parentNode: document.querySelector('#main'),
            jwt: this.jwt
        };
        this.JitsiAPI = new window.JitsiMeetExternalAPI(window.JITSI_URL, options);

        function readyToCloseListener() {
            document.getElementById('main').innerHTML = "";
        }

        this.JitsiAPI.addEventListeners({
            readyToClose: readyToCloseListener
        });
    }

    login() {
        let params = new URLSearchParams();
        params.append('pass', this.password);

        this.httpRequest("post", "/login.php", params)
            .then(data =>{

                this.setState({
                    userLevel: data
                })
            });

    }

    render() {
        if (this.password === null) {
            this.password = prompt("Bitte gib das Passwort ein.");
            this.login();
            this.getJWT();
        }

        return (
            <div className="App">
                <div className="sidebar">
                    {this.renderRooms()}
                    <div className="sidebar-content">
                        {this.JitsiAPI === null ? "" : this.JitsiAPI.getNumberOfParticipants()}
                    </div>
                    <div className="sidebar-footer">

                    </div>
                </div>
                <div className="main" id="main">
                    <div className="welcome">
                        <p>
                        {this.state.userLevel > 0 ? "Du hast dich erfolgreich angemeldet" : "Dein Passwort war nicht korrekt"}
                        </p>
                        <label htmlFor="username">Du kannst hier deinen Benutzernamen angeben:</label>
                        <input type="text" id="username" value={this.state.username} onChange={this.handleUsernameChange} placeholder="Username" />
                    </div>

                </div>
            </div>
        );
    }

}

export default App;
